import React from "react";
import SectorPage from "./SectorPage";
import Cover from "images/CareerFields/scaled/volunteering-cover-2_2560.webp";
import Icon1 from "images/mentorship.svg";
import Icon2 from "images/hospital.svg";
import Icon3 from "images/handshake.svg";
import Icon4 from "images/house.svg";
import Image1 from "images/volunteering-image1.webp";
import Image2 from "images/volunteering-image2.webp";
import Image3 from "images/volunteering-image3.webp";

const intro = (
  <>
    <strong>Are you an aspiring educator, social worker, or professional passionate about child development and community improvement ? </strong>
    Join HealthMission Africa's programs in Tanzania and gain experience along with valuable knowledge at schools, orphanages, and community organizations in Arusha, Tanzania.
  </>
);

const bulletPoints = [
  { icon: Icon1, text: "Practical experience in education and community projects" },
  { icon: Icon2, text: "Exposure to diverse cultural and social work practices" },
  { icon: Icon3, text: "Support and mentorship from experienced educators and social workers" },
  { icon: Icon4, text: "Comprehensive package with accommodation and food" }
];

const firstText = <p><strong>Our teaching programs offer </strong>both aspiring educators and professionals the opportunity to apply their knowledge in a practical setting. Volunteers assist local teachers in <strong>delivering lessons</strong>, <strong>organizing educational activities</strong>, and <strong>providing support to children</strong>. This invaluable experience will help you develop essential teaching skills and confidence as you engage in direct strong educational activities.<br /><br /><br />
  Participants will be part of a <strong>vibrant and diverse</strong> educational community, gaining insights into different teaching methods and cultural approaches to education. Your contributions will provide much-needed support and bring comfort and encouragement to children and orphans. <br /><br /><br />
  Additionally, this exposure will not only enhance your teaching abilities but may also inspire you to <strong>bring innovative practices back</strong> to your own educational environment.
</p>;

const secondText = <p>For those interested in social work and community development, <strong>our program offers</strong> the chance to engage in various projects aimed at <strong>improving the quality of life</strong> for marginalized communities. You will work with local organizations to support initiatives in areas such as women's empowerment, youth mentorship, and community health education. By participating in these programs, you will gain <strong>valuable experience</strong> in social work practices and contribute to sustainable development efforts in Tanzania.<br /><br /><br />
  This experience will deepen your understanding of the complexities and resourcefulness required to deliver social services in underserved communities. Moreover, it will equip you with the resilience and adaptability needed to make a <strong>meaningful impact</strong> in your future career.
</p>;

const thirdText = <p>As part of our <strong>comprehensive package</strong>, we offer the <strong>accommodation</strong> (with security), daily <strong>breakfast</strong> and <strong>dinner</strong>, an <strong>orientation day</strong>, <strong>airport pick-up and drop-off</strong>, as well as <strong>placement assistance</strong> with local schools and organizations.<br /><br /><br />
  But it’s not just about the professional experience. Our volunteers also have the opportunity to immerse themselves in Tanzanian culture and experience the beauty of the country. We offer assistance in organising activities such as safaris, trips to Zanzibar, Mount Kilimanjaro climb, hot springs day trips, local markets visits, and other cultural activities.<br /><br /><br />
  By combining professional skills development with cultural experiences, HealthMission Africa offers a comprehensive, immersive program that is unmatched in its scope and impact. Join us and experience the adventure of a lifetime while making a positive impact on the lives of others.
</p>;

const Volunteering = () => {
  return (
    <SectorPage
      cover={Cover}
      title={"Teaching, Social Work & Community Development"}
      intro={intro}
      bulletPoints={bulletPoints}
      textOne={firstText}
      textTwo={secondText}
      textThree={thirdText}
      imageOne={Image1}
      imageTwo={Image2}
      imageThree={Image3}
    />
  );
};

export default Volunteering;
