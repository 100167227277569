import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Box, Card, CardContent, Grid, useMediaQuery, useTheme, Typography, TextField } from "@mui/material";
import FadeInUp from "bundles/common/utils/FadeInUp";
import sh from "bundles/common/utils/sh";
import Title from "bundles/Main/components/Title";
import { validateDateRange } from "bundles/common/utils/utils";
import ContactForm from "bundles/Main/components/ContactForm";
import SeeOptionsButton from "buttons/see-options.svg";
import SeeMoreButton from "buttons/see-more-blue.svg";

const Pricing = () => {
  const authenticityToken = useSelector((state) => state.authenticity_token);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const i18nLocale = useSelector(state => state.i18nLocale);
  const [prices, setPrices] = useState([]);
  const [visibleCards, setVisibleCards] = useState(isDesktop? 12 : 4);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [showOptions, setShowOptions] = useState(false);
  const [optionsData, setOptionsData] = useState(null);
  const [seeOptionsHovered, setSeeOptionsHovered] = useState(false);
  const [seeMoreHovered, setSeeMoreHovered] = useState(false);

  const title = "<span style='font-weight: 400;'>The</span> <span style='font-weight: 800;'><em>Prices<em/></span>";

  const fetchPrices = () => {
    sh.get("/prices")
      .then((response) => {
        setPrices(response.data);
      })
      .catch((error) => {
        console.error("Error fetching prices:", error);
      });
  };

  const handleSeeMoreClick = () => {
    setVisibleCards(visibleCards + 4);
  };

  const handleSeeOptionsClick = () => {
    if (validateDateRange(fromDate, toDate, enqueueSnackbar)) {
      sh.post("/calculate_price", {
        authenticity_token: authenticityToken,
        from_date: fromDate,
        to_date: toDate
      })
        .then((response) => {
          const { totalPrice, pricePerDay, numberOfDays } = response.data;
          const options = {
            numberOfDays,
            totalPrice,
            pricePerDay,
          };
          setOptionsData(options);
          setShowOptions(true);
        })
        .catch((error) => {
          console.error("Error calculating price:", error);
          enqueueSnackbar("Error calculating price.", { variant: "error" });
        });
    }
  };

  const handleCardClick = (optional, fromDate, toDate) => {
    if (!optional) {
      navigate(`/${i18nLocale}/apply`);
    } else {
      navigate(`/${i18nLocale}/apply?start_date=${fromDate}&end_date=${toDate}`);
    }
  };

  useEffect(() => {
    fetchPrices();
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      bgcolor="#F8F8F8"
      sx={{ width: "100%" }}
    >
      <Title text={title} />

      <FadeInUp>
        {isDesktop ? (
          <Box style={{ display: "flex", justifyContent: "space-between", marginBottom: "24px" }}>
            <div style={{ marginLeft: "8rem", width: "60%" }}>
              <p style={{ color: theme.palette.secondary.main, paddingBottom: "4px", fontSize: "20px" }}>
                <strong>The package includes:</strong>
              </p>
              <ul style={{ paddingLeft: "18px", fontSize: "18px", listStyle: "circle" }}>
                <li>Accommodation (with security)</li>
                <li>Daily breakfast and dinner</li>
                <li>House cleaning every day</li>
                <li>Airport pick-up and drop-off (from/to Arusha or Kilimanjaro airport)</li>
                <li>An orientation day to acquaint you with the local culture and your new surroundings</li>
                <li>Hospital placement assistance, aligning with your choice of department (please note that additional fees are paid directly to your placement as per their guidelines)</li>
              </ul>
            </div>
            <div style={{ marginLeft: "6rem", width: "40%" }}>
              <p style={{ color: theme.palette.secondary.main, paddingBottom: "4px", fontSize: "20px" }}>
                <strong>The package excludes:</strong>
              </p>
              <ul style={{ paddingLeft: "18px", fontSize: "18px", listStyle: "circle" }}>
                <li>Flights</li>
                <li>Travel Insurance</li>
                <li>Visa-related costs</li>
                <li>Hospital and Supervision fees</li>
                <li>Weekend trips and tours</li>
                <li>Snacks, softs drinks, gifts and souvenirs</li>
              </ul>
            </div>
          </Box>
        ) : (
          <Box style={{ margin: "24px" }}>
            <div style={{ marginBottom: "24px" }}>
              <p style={{ color: theme.palette.secondary.main, paddingBottom: "4px", fontSize: "20px" }}>
                <strong>The package includes:</strong>
              </p>
              <ul style={{ paddingLeft: "18px", fontSize: "18px", listStyle: "circle" }}>
                <li>Accommodation (with security)</li>
                <li>Daily breakfast and dinner</li>
                <li>House cleaning every day</li>
                <li>Airport pick-up and drop-off (from/to Arusha or Kilimanjaro airport)</li>
                <li>An orientation day to acquaint you with the local culture and your new surroundings</li>
                <li>Hospital placement assistance, aligning with your choice of department (please note that additional fees are paid directly to your placement as per their guidelines)</li>
              </ul>
            </div>
            <div style={{ marginBottom: "48px" }}>
              <p style={{ color: theme.palette.secondary.main, paddingBottom: "4px", fontSize: "20px" }}>
                <strong>The package excludes:</strong>
              </p>
              <ul style={{ paddingLeft: "18px", fontSize: "18px", listStyle: "circle" }}>
                <li>Flights</li>
                <li>Travel Insurance</li>
                <li>Visa-related costs</li>
                <li>Hospital and Supervision fees</li>
                <li>Weekend trips and tours</li>
                <li>Snacks, softs drinks, gifts and souvenirs</li>
              </ul>
            </div>
          </Box>
        )}
      </FadeInUp>

      <FadeInUp
        style={{
          display: "flex",
          flexDirection: isDesktop ? "row" : "column",
          alignItems: "center",
          marginBottom: "32px",
          backgroundColor: "white",
          justifyContent:"space-between",
          borderRadius: "16px",
          padding: isDesktop ? "32px 64px" : "16px",
          width: "80%"
        }}
      >
        <Typography sx={{ fontSize: "18px !important", marginRight: isDesktop ? "16px" : "", marginBottom: isDesktop ? "" : "24px" }}>
          Calculate your Price:
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", width: isDesktop ? "auto" : "100%", marginBottom: isDesktop ? "" : "24px" }}>
          <TextField
            label="From"
            type="date"
            value={fromDate}
            onChange={(e) => setFromDate(e.target.value)}
            sx={{
              marginRight: "8px",
              width: isDesktop ? "auto " : "calc(50% - 8px)"
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            label="To"
            type="date"
            value={toDate}
            onChange={(e) => setToDate(e.target.value)}
            sx={{
              marginRight: "8px",
              width: isDesktop ? "auto " : "calc(50% - 8px)"
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>
        <img
          src={SeeOptionsButton}
          alt="See Options"
          style={{
            cursor: "pointer",
            transform: seeOptionsHovered ? "scale(1)" : "scale(0.9)",
            transition: "all 0.3s ease",
          }}
          onMouseEnter={() => setSeeOptionsHovered(true)}
          onMouseLeave={() => setSeeOptionsHovered(false)}
          onClick={handleSeeOptionsClick}
        />
      </FadeInUp>

      {/* Display additional card if "See Options" is clicked */}
      {showOptions && optionsData && (
        <Grid container spacing={2} style={{ paddingRight: "86px", paddingLeft: "86px", marginBottom: "16px" }}>
          <Grid item xs={12} sm={6} md={3}>
            <FadeInUp style={{ padding: "16px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100%" }}>
              <Card
                sx={{
                  width: "100%",
                  backgroundColor: "#E2EFFB",
                  transition: "transform 0.3s",
                  ":hover": {
                    transform: "scale(1.1)",
                    cursor: "pointer"
                  }
                }}
                onClick={() => handleCardClick(true, fromDate, toDate)}
              >
                <CardContent style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                  <Typography variant="h5" style={{ marginBottom: "8px", fontSize: "18px" }}><strong>{optionsData.numberOfDays} days</strong></Typography>
                  <Typography variant="h5" style={{ marginBottom: "8px", fontSize: "16px" }}>{optionsData.totalPrice.toFixed(0)}€</Typography>
                  <Typography variant="h5" style={{ marginBottom: "8px", fontSize: "14px" }}>{optionsData.pricePerDay.toFixed(2)}€ per day</Typography>
                </CardContent>
              </Card>
            </FadeInUp>
          </Grid>
        </Grid>
      )}

      {/* Display the initial 12 cards for desktop and 4 for mobile */}
      <FadeInUp style={{ marginRight: "auto", marginTop: "16px", marginBottom: "16px" }}>
        <Typography variant="h6" style={{ fontWeight: "bold", paddingLeft: "104px", fontSize: "1.5rem", textAlign: "left" }}>
          <strong>Other options:</strong>
        </Typography>
      </FadeInUp>
      <Grid container spacing={2} style={{ paddingRight: isDesktop ? "86px" : "68px", paddingLeft: isDesktop ? "86px" : "68px", marginBottom: "16px" }}>
        {prices.slice(0, visibleCards).map((price) => (
          <Grid item xs={12} sm={6} md={3} key={price.id}>
            <FadeInUp style={{ padding: "16px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100%" }}>
              <Card
                sx={{
                  width: "100%",
                  backgroundColor: "#E2EFFB",
                  transition: "transform 0.3s",
                  ":hover": {
                    transform: "scale(1.1)",
                    cursor: "pointer"
                  }
                }}
                onClick={() => handleCardClick(false, "", "")}
              >
                <CardContent style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                  <Typography variant="h5" style={{ marginBottom: "8px", fontSize : "18px" }}><strong>{price.weeks} weeks</strong></Typography>
                  <Typography variant="h5" style={{ marginBottom: "8px", fontSize: "16px" }}>{price.price}€</Typography>
                  <Typography variant="body1">
                    (≈ €{(price.price / (price.weeks * 7)).toFixed(0)}/day)
                  </Typography>
                </CardContent>
              </Card>
            </FadeInUp>
          </Grid>
        ))}
      </Grid>

      {/* "See More" button */}
      {visibleCards < prices.length && (
        <FadeInUp
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "8px 0 32px 0",
            cursor: "pointer",
            transform: seeMoreHovered ? "scale(1.1)" : "scale(1)",
            transition: "all 0.3s ease",
          }}
        >
          <img
            src={SeeMoreButton}
            alt="See More"
            onClick={handleSeeMoreClick}
            onMouseEnter={() => setSeeMoreHovered(true)}
            onMouseLeave={() => setSeeMoreHovered(false)}
          />
        </FadeInUp>
      )}
      <ContactForm />
    </Box>
  );
};

export default Pricing;
