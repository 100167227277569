import React, { useState, useReducer, useEffect } from "react";
import SearchSelect from "react-select";
import countries from "world-countries";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box, Button, InputBase, TextField, Typography, RadioGroup, FormControlLabel, Radio, Select, MenuItem, FormControl, Grid, useTheme, useMediaQuery, InputAdornment } from "@mui/material";
import { useSnackbar } from "notistack";
import { Link } from "react-router-dom";
import { BeatLoader } from "react-spinners";

import FadeInUp from "bundles/common/utils/FadeInUp";
import validator from "validator";
import sh from "bundles/common/utils/sh";
import { validateDateRange } from "bundles/common/utils/utils";
import SubmitButton from "buttons/submit.svg";
import blueLogo from "images/blue-logo.svg";

const countryOptions = countries.map((country) => ({
  value: country.name.common,
  label: country.name.common,
}));

const formReducer = (state, action) => {
  const initialFormState = {
    values: {
      fullName: "",
      email: "",
      phoneNumber: "",
      birthDate: "",
      gender: "",
      education: "",
      careerField: "",
      department: "",
      university: "",
      country: "",
      startDate: "",
      endDate: "",
      comment: ""
    },
    errors: {},
    loading: false
  };

  switch (action.type) {
  case "SET_VALUES":
    return {
      ...state,
      values: {
        ...state.values,
        [action.payload.name]: action.payload.value,
      },
    };
  case "SET_ERRORS":
    return {
      ...state,
      errors: {
        ...state.errors,
        [action.payload.name]: action.payload.error,
      },
    };
  case "SET_LOADING":
    return {
      ...state,
      loading: action.payload,
    };
  case "RESET_FORM":
    return initialFormState;
  default:
    return state;
  }
};

const Apply = () => {
  const authenticityToken = useSelector((state) => state.authenticity_token);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const i18nLocale = useSelector(state => state.i18nLocale);
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const startDateParam = queryParams.get("start_date");
  const endDateParam = queryParams.get("end_date");

  const initialFormState = {
    values: {
      fullName: "",
      email: "",
      phoneNumber: "",
      birthDate: "",
      gender: "",
      careerField: "",
      department: "",
      university: "",
      country: "",
      education: "",
      startDate: startDateParam || "",
      endDate: endDateParam || "",
      numberOfPeople: 1,
      comment: ""
    },
    errors: {},
    loading: false
  };

  const [state, dispatch] = useReducer(formReducer, initialFormState);
  const [submitHovered, setSubmitHovered] = useState(false);
  const { values, loading } = state;
  const [price, setPrice] = useState("");
  const [isCalculating, setIsCalculating] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const buttonStyle = {
    textTransform: "none",
    color: theme.palette.secondary.main,
    fontSize: "1rem",
    margin: theme.spacing(1),
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  };

  const careerFieldsOptions = [
    { label: "Medicine", value: "medicine" },
    { label: "Nursing", value: "nursing" },
    { label: "Dentistry", value: "dentistry" },
    { label: "Physiotherapy", value: "physiotherapy" },
    { label: "Teaching & Social Work", value: "volunteering" }
  ];

  const medicineAndNursingDepartments = [
    { label: "Emergency department", value: "emergency" },
    { label: "Surgery", value: "surgery" },
    { label: "Obstetrics / Gynecology", value: "obstetrics_gynecology" },
    { label: "Internal medicine (+ICU optional)", value: "internal_medicine" },
    { label: "Pediatrics", value: "pediatrics" }
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "careerField") {
      dispatch({
        type: "SET_VALUES",
        payload: { name: "department", value: "" }
      });
    }

    if (name === "education") {
      dispatch({
        type: "SET_VALUES",
        payload: { name: "university", value: "" }
      });
    }

    dispatch({
      type: "SET_VALUES",
      payload: { name, value }
    });
  };

  const handleCountryChange = (selectedOption) => {
    dispatch({ type: "SET_VALUES", payload: { name: "country", value: selectedOption?.value } });
  };

  const calculatePrice = (startDate, endDate) => {
    if (validateDateRange(startDate, endDate, enqueueSnackbar)) {
      setIsCalculating(true);
      setTimeout(() => {
        sh.post("/calculate_price", {
          authenticity_token: authenticityToken,
          from_date: startDate,
          to_date: endDate
        })
          .then((response) => {
            setPrice(response.data.totalPrice);
            setIsCalculating(false);
          })
          .catch((error) => {
            console.error("Error calculating price:", error);
            enqueueSnackbar("Error calculating price.", { variant: "error" });
            setIsCalculating(false);
          });
      }, 1400);
    } else {
      setPrice("");
    }
  };

  const validateForm = (values) => {
    let errors = {};

    if (!values.fullName.trim()) {
      errors.fullName = "Full name is required.";
    }

    if (!values.email.trim()) {
      errors.email = "Email is required.";
    } else if (!validator.isEmail(values.email)) {
      errors.email = "Email is not valid.";
    }

    if (!values.phoneNumber.trim()) {
      errors.phoneNumber = "Full name is required.";
    }

    if (!values.country.trim()) {
      errors.country = "Country is required.";
    }

    if (!values.birthDate.trim()) {
      errors.birthDate = "Birth date is required.";
    }

    if (!values.gender.trim()) {
      errors.gender = "Gender is required.";
    }

    if (!values.careerField.trim()) {
      errors.careerField = "Career field is required.";
    }

    if (["medicine", "nursing"].includes(values.careerField)) {
      if (!values.department.trim()) {
        errors.department = "Department is required.";
      }
    }

    if (!values.education.trim()) {
      errors.education = "Education level is required.";
    }

    if (values.education === "pregraduate" && !values.university.trim()) {
      errors.university = "University name is required.";
    }

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formErrors = validateForm(values);
    const isValid = Object.keys(formErrors).length === 0;

    if (validateDateRange(values.startDate, values.endDate, enqueueSnackbar)) {
      if (isValid) {
        setIsSubmitting(true);
        dispatch({ type: "SET_LOADING", payload: true });
        sh.post("/apply", {
          authenticity_token: authenticityToken,
          lead_data: {
            name: values.fullName,
            email: values.email,
            phone_number: values.phoneNumber,
            birth_date: values.birthDate,
            gender: values.gender,
            education: values.education,
            career_field: values.careerField,
            department: values.department,
            university: values.university,
            country: values.country,
          },
          application_data: {
            start_date: values.startDate,
            end_date: values.endDate,
            price: price,
            comment: values.comment,
          },
        })
          .then(() => {
            enqueueSnackbar("Application submitted successfully. We will get back to you soon !", { variant: "success" });
            setTimeout(() => {
              setIsSubmitting(false);
              const reloadUrl = window.location.pathname;
              window.location.href = reloadUrl;
            }, 1200);
          })
          .catch(() => {
            enqueueSnackbar("Failed to submit application.", { variant: "error" });
            setIsSubmitting(false);
          })
          .finally(() => {
            dispatch({ type: "SET_LOADING", payload: false });
          });
      } else {
        dispatch({ type: "SET_ERRORS", payload: formErrors });
        enqueueSnackbar(Object.values(formErrors).join(" "), { variant: "error" });
      }
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
    }, 800);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (values.startDate && values.endDate) {
      calculatePrice(values.startDate, values.endDate);
    }
  }, [values.startDate, values.endDate]);

  return (
    <>
      <Box component="form" onSubmit={handleSubmit} sx={{ width: isDesktop ? "60%" : "80%", mt: 1, mb: 2, mx: "auto" }}>
        <FadeInUp>
          <Typography
            variant="h2"
            style={{
              color: theme.palette.secondary.main,
              textAlign: "center",
              fontSize: "36px",
              paddingTop: "48px",
            }}
          >
            Join <strong><em>us!</em></strong>
          </Typography>
        </FadeInUp>
        <FadeInUp>
          <Grid container spacing={4} sx={{ mt: 2 }} justifyContent="center">
            {/* Full name and Email */}
            <Grid item xs={12} sm={6}>
              <InputBase
                fullWidth
                placeholder="Full name*"
                name="fullName"
                value={values.fullName}
                onChange={handleChange}
                sx={{
                  borderBottom: `2px solid ${theme.palette.secondary.main}`,
                  color: theme.palette.secondary.main,
                  textAlign: "center",
                  mb: 2,
                  fontSize: "1rem !important"
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputBase
                fullWidth
                placeholder="Email*"
                name="email"
                value={values.email}
                onChange={handleChange}
                sx={{
                  borderBottom: `2px solid ${theme.palette.secondary.main}`,
                  color: theme.palette.secondary.main,
                  textAlign: "center",
                  mb: 2,
                  fontSize: "1rem !important"
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <InputBase
                fullWidth
                placeholder="Phone number*"
                name="phoneNumber"
                value={values.phoneNumber}
                onChange={handleChange}
                sx={{
                  borderBottom: `2px solid ${theme.palette.secondary.main}`,
                  color: theme.palette.secondary.main,
                  textAlign: "center",
                  mb: 2,
                  mt: isDesktop ? 2 : "",
                  fontSize: "1rem !important"
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Box sx={{ minWidth: "100%" }}>
                <SearchSelect
                  options={countryOptions}
                  placeholder="Country*"
                  onChange={handleCountryChange}
                  isSearchable
                  styles={{
                    control: (base) => ({
                      ...base,
                      border: "none",
                      borderRadius: "none",
                      borderBottom: `2px solid ${theme.palette.secondary.main}`,
                      boxShadow: "none",
                      paddingTop: "12px",
                      background: "transparent",
                      "&:hover": { borderColor: theme.palette.secondary.main },
                    }),
                    placeholder: (base) => ({
                      ...base,
                      color: theme.palette.secondary.main,
                      fontSize: isDesktop ? "" : "1rem",
                    }),
                    singleValue: (base) => ({
                      ...base,
                      color: theme.palette.secondary.main,
                      fontSize: isDesktop ? "" : "1rem",
                    }),
                    valueContainer: (base) => ({
                      ...base,
                      padding: "0px"
                    }),

                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Grid container spacing={2}>
                {/* Birth Date */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    type="date"
                    name="birthDate"
                    label="Date of birth*"
                    value={values.birthDate}
                    onChange={handleChange}
                    variant="standard"
                    InputLabelProps={{
                      shrink: true,
                      style: { color: theme.palette.secondary.main },
                      sx: {
                        fontSize: "1.1rem !important"
                      }
                    }}
                    InputProps={{
                      style: { color: theme.palette.secondary.main }
                    }}
                    sx={{
                      borderBottom: `1px solid ${theme.palette.secondary.main}`,
                      "&:hover:before": {
                        borderBottom: "none",
                      },
                      "&:before": {
                        borderBottom: "none",
                      },
                      "&:after": {
                        borderBottom: "none",
                      },
                      "&:focus-within:before": {
                        borderBottom: "none",
                      },
                      "&:hover:after": {
                        borderBottom: "none",
                      },
                      marginBottom: "16px",
                    }}
                  />
                </Grid>

                {/* Gender */}
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth variant="standard" sx={{ mb: 2, mt: 0, paddingTop: "14px" }}>
                    <Select
                      name="gender"
                      value={values.gender}
                      onChange={handleChange}
                      displayEmpty
                      input={<InputBase />}
                      sx={{
                        borderBottom: `2px solid ${theme.palette.secondary.main}`,
                        color: theme.palette.secondary.main,
                        fontSize: "1rem !important"
                      }}
                    >
                      <MenuItem value="" disabled>
                        Gender*
                      </MenuItem>
                      <MenuItem value="male">Male</MenuItem>
                      <MenuItem value="female">Female</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>


            <Grid item xs={12} sm={6} >
              <FormControl fullWidth sx={{ mb: 2, mt: 1.8 }}>
                <Select
                  name="careerField"
                  value={values.careerField}
                  onChange={handleChange}
                  displayEmpty
                  input={<InputBase />}
                  sx={{
                    borderBottom: `2px solid ${theme.palette.secondary.main}`,
                    color: theme.palette.secondary.main,
                    fontSize: "1rem !important"
                  }}
                >
                  <MenuItem value="" disabled>
                    Career field*
                  </MenuItem>
                  {careerFieldsOptions.map(field => (
                    <MenuItem
                      key={field.value}
                      value={field.value}
                      sx={{ color: theme.palette.secondary.main, fontSize: "1rem !important" }}
                    >
                      {field.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              {["medicine", "nursing"].includes(values.careerField) && (
                <FormControl fullWidth sx={{ mb: 2, mt: 1.8 }}>
                  <Select
                    name="department"
                    value={values.department}
                    onChange={handleChange}
                    displayEmpty
                    input={<InputBase />}
                    sx={{
                      borderBottom: `2px solid ${theme.palette.secondary.main}`,
                      color: theme.palette.secondary.main,
                      fontSize: "1rem !important"
                    }}
                  >
                    <MenuItem value="" disabled>
                      Department*
                    </MenuItem>
                    {medicineAndNursingDepartments.map(department => (
                      <MenuItem
                        key={department.value}
                        value={department.value}
                        sx={{ color: theme.palette.secondary.main, fontSize: "1rem !important" }}
                      >
                        {department.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Grid>

            <Grid item xs={12} sm={12}>
              <FormControl component="fieldset" fullWidth>
                <RadioGroup
                  row
                  aria-label="education-level"
                  name="education"
                  value={values.educationLevel}
                  onChange={handleChange}
                  sx={{
                    justifyContent: "center",
                    color: theme.palette.secondary.main,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center"
                  }}
                >
                  <FormControlLabel
                    value="pregraduate"
                    control={<Radio sx={{ color: theme.palette.secondary.main }} />}
                    label="Pregraduate"
                    sx={{ color: theme.palette.secondary.main, marginRight: 8 }}
                  />
                  <FormControlLabel
                    value="postgraduate"
                    control={<Radio sx={{ color: theme.palette.secondary.main }} />}
                    label="Postgraduate"
                    sx={{ color: theme.palette.secondary.main }}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            {values.education === "pregraduate" && (
              <>
                <Grid item xs={12} sm={2}>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <InputBase
                    fullWidth
                    placeholder="University Name*"
                    name="university"
                    value={values.university}
                    onChange={handleChange}
                    sx={{
                      borderBottom: `2px solid ${theme.palette.secondary.main}`,
                      color: theme.palette.secondary.main,
                      textAlign: "center",
                      fontSize: "1rem !important",
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                </Grid>
              </>
            )}

            {/* Start date and End date */}
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                type="date"
                name="startDate"
                label="From when?"
                value={values.startDate}
                onChange={handleChange}
                variant="standard"
                InputLabelProps={{
                  shrink: true,
                  style: { color: theme.palette.secondary.main },
                  sx: {
                    fontSize: "1.1rem !important"
                  }
                }}
                InputProps={{
                  style: { color: theme.palette.secondary.main }
                }}
                sx={{
                  borderBottom: `1px solid ${theme.palette.secondary.main}`,
                  "&:hover:before": {
                    borderBottom: "none",
                  },
                  "&:before": {
                    borderBottom: "none",
                  },
                  "&:after": {
                    borderBottom: "none",
                  },
                  "&:focus-within:before": {
                    borderBottom: "none",
                  },
                  marginBottom: "16px",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                type="date"
                name="endDate"
                label="To when?"
                value={values.endDate}
                onChange={handleChange}
                variant="standard"
                InputLabelProps={{
                  shrink: true,
                  style: { color: theme.palette.secondary.main },
                  sx: {
                    fontSize: "1.1rem !important"
                  }
                }}
                InputProps={{
                  style: { color: theme.palette.secondary.main }
                }}
                sx={{
                  borderBottom: `1px solid ${theme.palette.secondary.main}`,
                  "&:hover:before": {
                    borderBottom: "none",
                  },
                  "&:before": {
                    borderBottom: "none",
                  },
                  "&:after": {
                    borderBottom: "none",
                  },
                  "&:focus-within:before": {
                    borderBottom: "none",
                  },
                  marginBottom: "16px",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              {isCalculating ? (
                <div style={{ textAlign: "center" }}>
                  <BeatLoader size={8} color="#173571" />
                </div>
              ) : price && (
                <Box sx={{ textAlign: "center" }}>
                  <Typography variant="h6" color="secondary" sx={{ fontSize: "1rem !important" }}>
                    Estimated Price: {price}€
                  </Typography>
                </Box>
              )}
            </Grid>

            {/* Comments */}
            <Grid item xs={12}>
              <Box
                sx={{
                  border: `1px solid ${theme.palette.secondary.main}`,
                  borderRadius: "4px",
                  p: "8px",
                  mb: 2
                }}
              >
                <InputBase
                  fullWidth
                  multiline
                  rows={4}
                  placeholder="Questions or Comments:"
                  name="comment"
                  value={values.comment}
                  onChange={handleChange}
                  sx={{
                    color: theme.palette.secondary.main,
                    textAlign: "left",
                    width: "100%",
                    "& .MuiInputBase-inputMultiline": {
                      padding: 0,
                    },
                    fontSize: "1rem !important"
                  }}
                />
              </Box>
            </Grid>

            {/* Submit button */}
            <Grid item xs={12}>
              <Box textAlign="center" mt={2}>
                {isSubmitting ?
                  <BeatLoader size={10} color="#173571" />
                  :
                  <img
                    src={SubmitButton}
                    alt="Submit"
                    onClick={!loading ? handleSubmit : undefined}
                    style={{
                      cursor: "pointer",
                      width: "160px",
                      transform: submitHovered ? "scale(1.1)" : "scale(1)",
                      transition: "all 0.3s ease",
                      opacity: loading ? 0.5 : 1,
                      pointerEvents: loading ? "none" : "auto"
                    }}
                    onMouseEnter={() => setSubmitHovered(true)}
                    onMouseLeave={() => setSubmitHovered(false)}
                  />
                }
              </Box>
            </Grid>
          </Grid>
        </FadeInUp>
      </Box>
      <Box sx={{ width: "100%", bgcolor: "#F8F8F8", p: 2 }}>
        <Grid container justifyContent="center" alignItems="center">
          {/* Internal Links */}
          <FadeInUp>
            <Button component="a" href={`/${i18nLocale}/apply`} rel="noopener noreferrer" sx={buttonStyle}>
              Join Us
            </Button>
          </FadeInUp>
          <FadeInUp>
            <Button component="a" href={`/${i18nLocale}/about_us`} rel="noopener noreferrer" sx={buttonStyle}>
              About
            </Button>
          </FadeInUp>
          {/* External Links */}
          <FadeInUp>
            <Button component="a" href="https://www.linkedin.com/company/healthmission-africa/" target="_blank" rel="noopener noreferrer" sx={buttonStyle}>
              Linkedin
            </Button>
          </FadeInUp>
          <FadeInUp>
            <Button component="a" href="https://www.instagram.com/healthmissionafrica/" target="_blank" rel="noopener noreferrer" sx={buttonStyle}>
              Instagram
            </Button>
          </FadeInUp>
          {/* Logo */}
          <div style={{ display: "flex" }}>
            <FadeInUp>
              <Link to={`/${i18nLocale}`} style={{ textDecoration: "none", color: "inherit" }}>
                <img src={blueLogo} alt="HealthMission Africa Logo" style={{ height: "50px", marginLeft: isDesktop ? theme.spacing(80) : theme.spacing(2) }} />
              </Link>
            </FadeInUp>
          </div>
        </Grid>
      </Box>
    </>
  );
};

export default Apply;
