import React from "react";
import SectorPage from "./SectorPage";
import Cover from "images/CareerFields/scaled/medical-healthcare-cover_2560.webp";
import Icon1 from "images/medicine.svg";
import Icon2 from "images/mentorship.svg";
import Icon3 from "images/hospital.svg";
import Icon4 from "images/house.svg";
import Image1 from "images/mns-image-3.png";
import Image2 from "images/mns-image1.png";

const intro = (
  <>
    <strong>Are you a medical or nursing student/professional looking for a transformative experience in Tanzania ? </strong>
    Join HealthMission's medical internship program and gain <strong>hands-on experience</strong>,
    along with valuable clinical knowledge, in the largest public hospital in Arusha, Tanzania.
  </>
);

const bulletPoints = [
  { icon: Icon1, text: "Hands-on experience in unique healthcare system" },
  { icon: Icon2, text: "Mentorship from experienced clinicians" },
  { icon: Icon3, text: "Exposure to diverse medical cases" },
  { icon: Icon4, text: "Comprehensive package with accommodation and food" }
];

const firstText = <p><strong>Our program offers</strong> both students and professionals the opportunity to apply their theoretical knowledge in a practical setting, which will help you develop essential skills and confidence as you engage in direct patient care.<br /><br />
  The volunteers are immersed in a  <strong>unique healthcare system</strong> that differs from their home countries. With limited resources and funding, students will witness firsthand the challenges and triumphs of providing medical care in a resource-constrained environment. This experience will <strong>broaden your perspective</strong> and <strong>enhance your adaptability</strong> as a <strong>healthcare professional.</strong><br /><br />
  Under the supervision of experienced clinicians, our interns receive mentorship and guidance throughout their placement. You will have the choice to intern in departments such as the Emergency Department, Surgery (general and orthopedic), Pediatrics, Obstetrics/Gynecology, Internal Medicine (+ICU), and more. The exposure to different patient demographics and health conditions will broaden your clinical expertise and enhance your ability to diagnose and treat various medical conditions.<br /><br />
  To ensure that you feel prepared, we provide you with materials to review before your arrival (though it is not mandatory). This preparation will enable you to make the most of your hands-on experience from day one.
</p>;

const secondText = <p>As part of our <strong>comprehensive package</strong>, we offer the <strong>accommodation</strong> (with security), daily <strong>breakfast</strong> and <strong>dinner</strong>, an <strong>orientation day</strong>, <strong>airport pick-up and drop-off</strong>, as well as <strong>hospital placement assistance</strong>.<br /><br /><br />
  But it’s not just about the clinical experience. Our volunteers also have the opportunity to immerse themselves in Tanzanian culture and experience the beauty of the country. We offer assistance in organising activities such as safaris, trips to Zanzibar, Mount Kilimanjaro climb, hot springs day trips, local markets visits, and other cultural activities.<br /><br /><br />
  By combining clinical skills development with cultural experiences, HealthMission Africa offers a comprehensive, immersive program that is unmatched in its scope and impact. Join us and experience the adventure of a lifetime while making a positive impact on the lives of others.
</p>;

const MedicalAndNursing = () => {
  return (
    <SectorPage
      cover={Cover}
      title={"Medical & Nursing"}
      intro={intro}
      bulletPoints={bulletPoints}
      textOne={firstText}
      textTwo={secondText}
      imageOne={Image1}
      imageTwo={Image2}
    />
  );
};

export default MedicalAndNursing;
